import React, { useState, useEffect, useRef } from 'react'

import IconArrow from '@/assets/images/svg/arrow.svg'

export const Select = ({ options, onChange, value, placeholder }) => {
  const [selectOption, setSelectOption] = useState(null)
  const [openOptions, setOpenOptions] = useState(true)
  const selectRef = useRef()

  useEffect(() => {
    if (!!value && value.length > 0) {
      setSelectOption(options.filter((option) => option.value === value)[0])
    }
  }, [value])

  const _handleSelectOption = (value) => {
    setSelectOption(value)
  }

  useEffect(() => {
    setOpenOptions(false)
    if (selectOption) {
      onChange(selectOption.value)
    }
  }, [selectOption])

  useEffect(() => {
    document.body.addEventListener(
      'click',
      (e) => e.target !== selectRef.current && setOpenOptions(false)
    )
  }, [])
  return (
    <div className="select-wrapper">
      <div
        className="select-input select-input-placeholder"
        onClick={() => setOpenOptions((prev) => !prev)}
        ref={selectRef}
      >
        {selectOption ? (
          <span className="is-selected">{selectOption.label}</span>
        ) : (
          <span>{placeholder}</span>
        )}
        <IconArrow className="select-input-arrow" />
      </div>
      <div
        className={`select-options${openOptions ? ' active' : ''}`}
        onBlur={() => setOpenOptions(false)}
      >
        {options.map((item) => (
          <div
            key={item.value}
            className={`select-options-item${
              item === selectOption ? ' active' : ''
            }`}
            onClick={() => _handleSelectOption(item)}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  )
}
