import { useStaticQuery, graphql } from 'gatsby'

export const useFetchRoutes = () => {
  const { wordpress } = useStaticQuery(
    graphql`
      query {
        wordpress {
          routes(first: 999) {
            nodes {
              id
              slug
              routeSchema {
                routes {
                  poing
                }
                routeType
              }
            }
          }
        }
      }
    `
  )
  return wordpress.routes.nodes
}
