import React, { useState, useContext, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './schema'
import axios from 'axios'
import { useLoadScript } from '@react-google-maps/api'
import InputMask from 'react-input-mask'

import IconPlus from '@/assets/images/svg/plus.svg'

const ModalFormContext = React.createContext()

const libraries = ['places']

export const useModalForm = () => {
  return useContext(ModalFormContext)
}

export const ModalForm = ({ children }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBjk3-gZuUK41KkCT_706fwXqZ874OsQiQ',
    language: 'ua',
    libraries,
  })
  const { register, handleSubmit, control, errors, clearErrors } = useForm({
    resolver: yupResolver(schema),
  })
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const _handleClose = () => {
    setModalOpen(false)
    setIsLoading(false)
    setSuccess(false)
    setError(false)
  }

  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.removeAttribute('style')
    }
  }, [modalOpen])

  const _submitForm = (data) => {
    setIsLoading(true)
    let formData = new FormData()
    formData.append('action', 'send_mail')
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value)
    }
    axios
      .post(
        'https://admin.podillya-tour.com.ua/wp-admin/admin-ajax.php',
        formData
      )
      .then((response) => {
        setSuccess(true)
        setIsLoading(false)
      })
      .catch((error) => {
        setError(false)
        setIsLoading(false)
      })
  }

  const _handleError = () => {
    setError(false)
  }

  return (
    <ModalFormContext.Provider value={{ setModalOpen, isLoaded }}>
      {children}
      {modalOpen && (
        <div className="page-form active">
          <div onClick={_handleClose} className="page-form__bg"></div>
          {!success && !error ? (
            <div className="page-form__wrpr">
              <IconPlus className="page-form-close" onClick={_handleClose} />
              <h2 className="page-form__title">Замовити автобус</h2>
              <form action="" className="order-bus">
                <input
                  type="text"
                  className={`input${
                    errors.hasOwnProperty('name') ? ' input-error' : ''
                  }`}
                  ref={register}
                  name="name"
                  placeholder="Ім’я"
                />
                {errors.hasOwnProperty('name') && (
                  <p className="input__error-text">{errors.name?.message}</p>
                )}
                <Controller
                  control={control}
                  name="phone"
                  defaultValue=""
                  render={(props) => (
                    <InputMask
                      ref={props.ref}
                      mask="+ 380 99 999 99 99"
                      placeholder="Телефон"
                      maskChar={null}
                      className={`input${
                        errors.hasOwnProperty('phone') ? ' input-error' : ''
                      }`}
                      onBlur={props.onBlur}
                      onChange={props.onChange}
                      value={props.value}
                      onFocus={props.onFocus}
                    />
                  )}
                />
                {errors.hasOwnProperty('phone') && (
                  <p className="input__error-text">{errors.phone?.message}</p>
                )}
                <input
                  type="email"
                  className={`input${
                    errors.hasOwnProperty('email') ? ' input-error' : ''
                  }`}
                  ref={register}
                  name="email"
                  placeholder="Email"
                />
                {errors.hasOwnProperty('email') && (
                  <p className="input__error-text">{errors.email?.message}</p>
                )}
                <div
                  className={`btn btn_gradient${isLoading ? ' waiting' : ''}`}
                  onClick={handleSubmit(_submitForm)}
                >
                  Замовити
                </div>
              </form>
            </div>
          ) : success ? (
            <div className="page-form__wrpr">
              <IconPlus className="page-form-close" onClick={_handleClose} />
              <h2 className="page-form__title">
                Дякуемо! Ваша заявка прийнята
              </h2>
              <div className="btn btn_gradient" onClick={_handleClose}>
                Завершити
              </div>
            </div>
          ) : (
            <div className="page-form__wrpr">
              <IconPlus className="page-form-close" onClick={_handleClose} />
              <h2 className="page-form__title">Вибачте, щось пішло не так</h2>
              <div className="btn btn_gradient" onClick={_handleError}>
                Повернутися до форми
              </div>
            </div>
          )}
        </div>
      )}
    </ModalFormContext.Provider>
  )
}
