import React from 'react'
import { Link } from 'gatsby'

export const RouteCard = ({ data }) => {
  const points = data.routeSchema.routes
  return (
    <div className="routes__card">
      <div className="routes__card-top">
        <div className="routes__from-to">
          {points.map((point, index) => (
            <div key={index} className="routes__from-to__item">
              {point.poing}
            </div>
          ))}
        </div>
        <div className="routes__date"></div>
      </div>
      <Link to={`/routes/${data.slug}`} className="btn routes__btn">
        {data.routeSchema.routeType === 'local'
          ? 'По території України'
          : 'Міжнародний маршрут'}
      </Link>
    </div>
  )
}
