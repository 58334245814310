import React, { useState, useEffect } from 'react'

export const Autocomplete = ({
  defaultValue,
  options,
  getOptionLabel,
  onChange,
  name,
  placeholder,
}) => {
  const [value, setValue] = useState('')
  const [selectedOption, setSelectedOption] = useState(null)
  const [onFocus, setOnFocus] = useState(false)
  const [findValue, setFindValue] = useState([])

  let timeFunction

  const _inputChange = (value) => {
    setValue(value)
  }

  const _handleOption = (value) => {
    if (value !== selectedOption) {
      setValue(getOptionLabel(value))
      setSelectedOption(value)
    } else {
      setValue('')
      setSelectedOption(null)
    }
    setOnFocus(false)
  }

  const _handleBlur = () => {
    clearTimeout(timeFunction)
    if (value.length === 0) {
      setValue('')
      setSelectedOption(null)
    } else {
      setValue(getOptionLabel(selectedOption))
    }
    timeFunction = setTimeout(() => {
      setOnFocus(false)
    }, 500)
  }

  useEffect(() => {
    onChange(selectedOption)
  }, [selectedOption])

  useEffect(() => {
    if (defaultValue) {
      setValue(getOptionLabel(defaultValue))
      setSelectedOption(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    const filterOptions = options.filter(
      (option) =>
        getOptionLabel(option).toLowerCase().indexOf(value.toLowerCase()) === 0
    )
    setFindValue(filterOptions.filter((option, index) => index <= 8))
  }, [value])

  return (
    <div className="autocomplete">
      <input
        autoComplete="off"
        onChange={(e) => _inputChange(e.target.value)}
        value={value}
        type="text"
        className="input"
        name={name}
        placeholder={placeholder}
        onFocus={() => setOnFocus(true)}
        onBlur={_handleBlur}
      />
      <div className="autocomplete__list">
        {onFocus && findValue.length > 0 && (
          <div className="autocomplete__list_container">
            {findValue.map((value, index) => {
              const isSelectedOption = () => {
                if (selectedOption) {
                  return selectedOption == value
                    ? 'autocomplete__item active'
                    : 'autocomplete__item'
                }
                return 'autocomplete__item'
              }
              return (
                <div
                  key={index}
                  className={isSelectedOption()}
                  onClick={() => _handleOption(value)}
                >
                  {getOptionLabel(value)}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
