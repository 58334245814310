import React from 'react'
import PropTypes from 'prop-types'
import Parser from 'html-react-parser'

export const Article = ({ content }) => {
  return <div>{Parser(content)}</div>
}

Article.propTypes = {
  content: PropTypes.string.isRequired,
}
