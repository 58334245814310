import React, { useState, useEffect } from 'react'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'

export const PlaceAutocomplete = ({ onChange, name, placeholder, remove }) => {
  const [selected, setSelected] = useState(null)
  const [onFocus, setOnFocus] = useState(false)
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 48.2558897, lng: () => 26.6927347 },
      radius: 700 * 1000,
    },
    debounce: 300,
  })

  useEffect(() => {
    if (!onFocus) {
      if (selected) {
        setValue(selected.description)
      } else {
        setValue('')
      }
    }
  }, [selected, onFocus])

  let timeFunction

  const _handleBlur = () => {
    clearTimeout(timeFunction)
    timeFunction = setTimeout(() => setOnFocus(false), 200)
  }

  const _handleSelect = async (value) => {
    try {
      setValue(value.description)
      setSelected(value)
      const result = await getGeocode({ address: value.description })
      const { lat, lng } = await getLatLng(result[0])
      onChange({ point: { lat, lng }, label: value.description })
    } catch (error) {
      console.log('error')
    }
  }

  return (
    <div className="place-autocomplete">
      <input
        autoComplete="off"
        onChange={(e) => setValue(e.target.value)}
        value={value}
        type="text"
        className={`input${remove ? ' input-has-remove' : ''}`}
        name={name}
        placeholder={placeholder}
        onFocus={() => setOnFocus(true)}
        onBlur={_handleBlur}
      />
      {remove && (
        <div className="point-remove" onClick={remove}>
          <span></span>
          <span></span>
        </div>
      )}
      <div className="autocomplete__list">
        {onFocus && data.length > 0 && (
          <div className="autocomplete__list_container">
            {data.map((value) => {
              const isSelectedOption = () => {
                if (selected) {
                  return selected == value
                    ? 'autocomplete__item active'
                    : 'autocomplete__item'
                }
                return 'autocomplete__item'
              }
              return (
                <div
                  key={value.place_id}
                  className={isSelectedOption()}
                  onClick={() => _handleSelect(value)}
                >
                  {value.description.length > 22
                    ? `${value.description.slice(0, 22)}...`
                    : value.description}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
