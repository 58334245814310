import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './schema'
import axios from 'axios'
import InputMask from 'react-input-mask'

import IconPlus from '@/assets/images/svg/plus.svg'

export const ReservationForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const { handleSubmit, errors, control, clearErrors } = useForm({
    resolver: yupResolver(schema),
  })
  const _handleClose = () => {
    setIsLoading(false)
    setSuccess(false)
    setError(false)
  }
  const _submitForm = (data) => {
    setIsLoading(true)
    let formData = new FormData()
    formData.append('action', 'send_reservation')
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value)
    }
    axios
      .post(
        'https://admin.podillya-tour.com.ua/wp-admin/admin-ajax.php',
        formData
      )
      .then((response) => {
        if (response.data.success) {
          setSuccess(true)
          setIsLoading(false)
        } else {
          setError(false)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (success) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.removeAttribute('style')
    }
  }, [success])

  useEffect(() => {
    if (error) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.removeAttribute('style')
    }
  }, [error])

  return (
    <>
      <section className="section reservation">
        <form onSubmit={handleSubmit(_submitForm)}>
          <div className="container">
            <h2 className="title_h2">Хочете забронювати квиток?</h2>
            <p className="text">
              Залиште свій номер телефону та наш менеджер допоможе вам з
              бронюванням.
            </p>
            <div action="" className="reservation-form">
              <div className="input-container">
                <Controller
                  control={control}
                  name="phone"
                  defaultValue=""
                  render={(props) => (
                    <InputMask
                      ref={props.ref}
                      mask="+380 99 999 99 99"
                      placeholder="+380 00 000 00 00"
                      maskChar={null}
                      className={`input${
                        errors.hasOwnProperty('phone') ? ' input-error' : ''
                      }`}
                      onBlur={props.onBlur}
                      onChange={props.onChange}
                      value={props.value}
                      onFocus={props.onFocus}
                    />
                  )}
                />
                {errors.hasOwnProperty('phone') && (
                  <p className="input__error-text">{errors.phone?.message}</p>
                )}
              </div>
              <button
                className={`btn btn_gradient${isLoading ? ' waiting' : ''}`}
              >
                Розрахувати
              </button>
            </div>
          </div>
        </form>
      </section>
      {success && (
        <div className="page-form active">
          <div onClick={_handleClose} className="page-form__bg"></div>
          <div className="page-form__wrpr">
            <IconPlus className="page-form-close" onClick={_handleClose} />
            <h2 className="page-form__title">Дякуемо! Ваша заявка прийнята</h2>
            <div className="btn btn_gradient" onClick={_handleClose}>
              Завершити
            </div>
          </div>
        </div>
      )}
      {error && (
        <div className="page-form active">
          <div onClick={_handleClose} className="page-form__bg"></div>
          <div className="page-form__wrpr">
            <IconPlus className="page-form-close" onClick={_handleClose} />
            <h2 className="page-form__title">Вибачте, щось пішло не так</h2>
            <div className="btn btn_gradient" onClick={_handleClose}>
              Повернутися до форми
            </div>
          </div>
        </div>
      )}
    </>
  )
}
