import React, { useEffect, useLayoutEffect, useState } from 'react'
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  Polyline,
} from '@react-google-maps/api'

import mapStyle from './mapStyle'
import markerIcon from './markerIcon'

const mapContainerStyle = {
  width: '100%',
  height: '100%',
}
const options = { styles: mapStyle }

export const Map = ({ markers, center, zoom, directions }) => {
  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={zoom}
      center={center}
      options={options}
    >
      {markers &&
        markers.map((marker, index) => (
          <Marker
            key={index}
            position={marker}
            icon={{
              ...markerIcon,
              anchor: new window.google.maps.Point(16.5, 45),
            }}
          />
        ))}
      {directions && (
        <DirectionsRenderer
          directions={directions}
          markerOptions={markerIcon}
          options={{
            markerOptions: {
              icon: {
                ...markerIcon,
                anchor: new window.google.maps.Point(16.5, 45),
              },
            },
            polylineOptions: {
              strokeColor: '#e75e20',
              strokeOpacity: 0.3,
              strokeWeight: 5,
              clickable: false,
            },
          }}
        />
      )}
    </GoogleMap>
  )
}
