import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import { useSiteMenuData } from '@/hooks'
import { useModalForm } from '@/components'

import '@styles/main.scss'

export const Header = () => {
  const [openMenu, setOpenMenu] = useState(false)
  const [mobNav, setMobNav] = useState(false)
  const menuData = useSiteMenuData()
  const { setModalOpen } = useModalForm()

  const _toggleOpenMenu = () => {
    setOpenMenu((prev) => !prev)
  }

  useEffect(() => {
    let pageYHistory = window.pageYOffset

    const scrollMenu = () => {
      const pageY = window.pageYOffset
      const nav = document.querySelector('.nav__container')
      const navHeight = nav.offsetHeight

      if (pageYHistory > pageY) {
        // scroll up
        if (pageY === 0) {
          setMobNav(false)
        } else {
          setMobNav(true)
          nav.style.transform = 'translateY(0)'
        }
      } else {
        // scroll down
        if (pageY > navHeight) {
          nav.style.transform = 'translateY(-100%)'
        }
      }
      pageYHistory = pageY
    }

    window.addEventListener('scroll', scrollMenu)
  }, [])

  return (
    <div className={`nav__container${mobNav ? ' active' : ''}`}>
      <div className="container">
        <nav className="nav">
          <Link to="/" className="logo" />
          <ul className="menu">
            {menuData.map((item) => (
              <li key={item.id} className="menu__item">
                <Link
                  className="menu__link"
                  to={`${item.path}`}
                  activeClassName="active"
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
          <div
            className="btn btn-nav open-form"
            onClick={() => setModalOpen(true)}
          >
            Замовити автобус
          </div>
          <div
            id="burger"
            data-menu={openMenu ? 'active' : 'not-active'}
            onClick={_toggleOpenMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </nav>
      </div>
      <ul
        className="menu menu-mob"
        data-menu={openMenu ? 'active' : 'not-active'}
      >
        {menuData.map((item) => (
          <li key={item.id} className="menu__item">
            <Link className="menu__link" to={`${item.path}`}>
              {item.label}
            </Link>
          </li>
        ))}
        <li className="menu_item">
          <div
            className="btn btn-nav open-form"
            onClick={() => setModalOpen(true)}
          >
            Замовити автобус
          </div>
        </li>
      </ul>
    </div>
  )
}
