import * as yup from 'yup'

export const schema = yup.object().shape({
  phone: yup
    .string()
    .min(17, "Поле обов'язкове")
    .max(17, "Поле обов'язкове")
    .required("Поле обов'язкове"),
})

export default schema
