// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-buses-index-js": () => import("./../../../src/templates/Buses/index.js" /* webpackChunkName: "component---src-templates-buses-index-js" */),
  "component---src-templates-calculator-index-js": () => import("./../../../src/templates/Calculator/index.js" /* webpackChunkName: "component---src-templates-calculator-index-js" */),
  "component---src-templates-main-index-js": () => import("./../../../src/templates/Main/index.js" /* webpackChunkName: "component---src-templates-main-index-js" */),
  "component---src-templates-route-index-js": () => import("./../../../src/templates/Route/index.js" /* webpackChunkName: "component---src-templates-route-index-js" */),
  "component---src-templates-routes-index-js": () => import("./../../../src/templates/Routes/index.js" /* webpackChunkName: "component---src-templates-routes-index-js" */)
}

