import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './schema'
import axios from 'axios'

import InputMask from 'react-input-mask'

import IconPlus from '@/assets/images/svg/plus.svg'

export const CalculatorModalForm = ({ openModal, setOpenModal, data }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const { register, handleSubmit, control, errors, clearErrors } = useForm({
    resolver: yupResolver(schema),
  })
  const _handleClose = () => {
    setIsLoading(false)
    setSuccess(false)
    setError(false)
    setOpenModal(false)
  }
  const _submitForm = (data) => {
    setIsLoading(true)
    let formData = new FormData()
    formData.append('action', 'calculator_form')
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value)
    }
    axios
      .post(
        'https://admin.podillya-tour.com.ua/wp-admin/admin-ajax.php',
        formData
      )
      .then((response) => {
        if (response.data.success) {
          setSuccess(true)
          setIsLoading(false)
        } else {
          setError(false)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (success) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.removeAttribute('style')
    }
  })

  useEffect(() => {
    if (success) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.removeAttribute('style')
    }
  }, [success])

  useEffect(() => {
    if (error) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.removeAttribute('style')
    }
  }, [error])

  return (
    <>
      {openModal && data && (
        <div className="page-form active">
          <div onClick={_handleClose} className="page-form__bg"></div>
          <div className="page-form__wrpr page-form__wrpr_calculator">
            <IconPlus className="page-form-close" onClick={_handleClose} />
            <h2 className="page-form__title">
              Підрахованна вартість маршруту, складає:{' '}
              <span className="page-form__title_price">{data.sum}</span>
              &nbsp;грн.
            </h2>
            <div className="page-form__alert-box">
              <h3 className="page-form__alert-box_title">Увага!</h3> Даний
              розрахунок не є точним, щоб дізнатись більш точнішу сумму будь
              ласка заповніть форму, і ми з вами зв'яжемося
            </div>
            <form action="" className="order-bus">
              <input
                ref={register}
                type="hidden"
                name="from"
                value={data['from']}
              />
              <input
                ref={register}
                type="hidden"
                name="to"
                value={data['to']}
              />
              <input
                ref={register}
                type="hidden"
                name="sum"
                value={data['sum']}
              />
              <input
                ref={register}
                type="hidden"
                name="routeType"
                value={data['routeType']}
              />
              <input
                ref={register}
                type="hidden"
                name="busType"
                value={data['busType']}
              />
              <input
                ref={register}
                type="hidden"
                name="time"
                value={data['time']}
              />
              <input
                type="text"
                className={`input${
                  errors.hasOwnProperty('name') ? ' input-error' : ''
                }`}
                ref={register}
                name="name"
                placeholder="Ім’я"
              />
              {errors.hasOwnProperty('name') && (
                <p className="input__error-text">{errors.name?.message}</p>
              )}
              <Controller
                control={control}
                name="phone"
                defaultValue=""
                render={(props) => (
                  <InputMask
                    ref={props.ref}
                    mask="+ 380 99 999 99 99"
                    placeholder="Телефон"
                    maskChar={null}
                    className={`input${
                      errors.hasOwnProperty('phone') ? ' input-error' : ''
                    }`}
                    onBlur={props.onBlur}
                    onChange={props.onChange}
                    value={props.value}
                    onFocus={props.onFocus}
                  />
                )}
              />
              {errors.hasOwnProperty('phone') && (
                <p className="input__error-text">{errors.phone?.message}</p>
              )}
              <input
                type="email"
                className={`input${
                  errors.hasOwnProperty('email') ? ' input-error' : ''
                }`}
                ref={register}
                name="email"
                placeholder="Email"
              />
              {errors.hasOwnProperty('email') && (
                <p className="input__error-text">{errors.email?.message}</p>
              )}
              <div
                className={`btn btn_gradient${isLoading ? ' waiting' : ''}`}
                onClick={handleSubmit(_submitForm)}
              >
                Відправити данні
              </div>
            </form>
          </div>
        </div>
      )}
      {success && (
        <div className="page-form active">
          <div onClick={_handleClose} className="page-form__bg"></div>
          <div className="page-form__wrpr">
            <IconPlus className="page-form-close" onClick={_handleClose} />
            <h2 className="page-form__title">Дякуемо! Ваша заявка прийнята</h2>
            <div className="btn btn_gradient" onClick={_handleClose}>
              Завершити
            </div>
          </div>
        </div>
      )}
      {error && (
        <div className="page-form active">
          <div onClick={_handleClose} className="page-form__bg"></div>
          <div className="page-form__wrpr">
            <IconPlus className="page-form-close" onClick={_handleClose} />
            <h2 className="page-form__title">Вибачте, щось пішло не так</h2>
            <div className="btn btn_gradient" onClick={_handleClose}>
              Повернутися до форми
            </div>
          </div>
        </div>
      )}
    </>
  )
}
