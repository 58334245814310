import { useStaticQuery, graphql } from 'gatsby'

export const useSiteMenuData = () => {
  const { wordpress } = useStaticQuery(
    graphql`
      query MenuData {
        wordpress {
          menu(id: "2", idType: DATABASE_ID) {
            menuItems {
              nodes {
                id
                label
                title
                url
                path
              }
            }
          }
        }
      }
    `
  )
  return wordpress.menu.menuItems.nodes
}
