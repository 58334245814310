import { useStaticQuery, graphql } from 'gatsby'

export const useCities = () => {
  const { allCity } = useStaticQuery(
    graphql`
      query Cities {
        allCity {
          nodes {
            cityId
            name
          }
        }
      }
    `
  )
  return allCity.nodes
}
