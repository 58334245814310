import * as yup from 'yup'

export const schema = yup.object().shape({
  name: yup.string().required("Поле обов'язкове"),
  phone: yup
    .string()
    .min(18, "Поле обов'язкове")
    .max(18, "Поле обов'язкове")
    .required("Поле обов'язкове"),
  email: yup.string().email('Не валідна електрона пошта'),
})

export default schema
